import React from "react"

import "../styles/global.scss";

import {SimpleLayout} from "../components/layout"
import SEO from "../components/seo"
import {Footer} from "../components/footer";

const NotFoundPage = () => (
    <SimpleLayout>
        <SEO title="404: Not found" />
        <div className="full-screen-container not-found-container">
            <h1 className="title">NOT FOUND :(</h1>
            <Footer/>
        </div>
    </SimpleLayout>
);

export default NotFoundPage
